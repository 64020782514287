<template>
  <div id="app" ref="mainApp">

    <div class="logo-wrapper" 
      @click="switchRoute({name: 'home'})" 
      @mouseover="animLogo.play()" 
      @mouseleave="animLogo.stop()">
      <lottie v-show="($route.name != 'blog' && $route.name != 'post')"
       id="logo" :options="logoAnimOptions" 
       :height="100" :width="200" 
       @animCreated="handleLogoAnimation"/>
    </div>
    <div class="badge" :class="{badgeShow: badgeShow}"><img src="./assets/email.png">Your message was sent.</div>

    <div class="lazyLoad" v-if="lazyLoad">
      <transition name="monocleOpen">
        <!-- Nik imagekit-->
        <!--<img v-show="modalOpened" src="./assets/monoc.png" id="monocle">-->
        <img v-show="modalOpened" src="https://ik.imagekit.io/nnol5se8mol/monoc_Q0etJyPU_.png" id="monocle">
      </transition>
      <transition name="modalPop">
        <div id="contactPopup" v-show="modalOpened">
          <h2>Tell us about your project</h2>
          <form class="contactForm">
            <label>Name</label>
            <input name="name" type="text" v-model="name" :maxlength="25" @keydown="nameErr = false" class="animated" :class="{'shake redText': nameErr}">
            <label>Phone</label>
            <input name="phone" type="text" @keypress="checkPhone($event)" v-model="phone" onKeyPress="if(this.value.length==20) return false;" @keydown="phoneErr = false" class="animated" :class="{'shake redText': phoneErr}">
            <label>Email</label>
            <input name="email" type="email" v-model="email" :maxlength="30" @keydown="emailErr = false" class="animated" :class="{'shake redText': emailErr}">
            <label>Message</label>
            <textarea name="message" id="" cols="30" rows="3" :maxlength="200" v-model="message" @keydown="messageErr = false" class="animated" :class="{'shake redText': messageErr}"></textarea>
            <small>We solemnly promise never to spam you.</small>
            <div class="btn animated" @click="sendModalAttempt()">
              <div></div>
              <span>Send</span>
            </div>
            <div id="mailandphonemodal" class="animated" :class="{fadeInUp: modalOpened}">
              <div><a :href="`tel:${this.phoneWithNoSpaces}`"><img src="./assets/phone.png"></a><span>{{footerData.phone}}</span></div>
              <div><a :href="`mailto:${this.footerData.email}`"><img src="./assets/email.png"></a><span>{{footerData.email}}</span></div>
            </div>
          </form>
        </div>
      </transition>
      <div @click="modalOpened ? $store.state.modalOpened = false : showMenu = !showMenu" class="icon" :class="{active: (showMenu || modalOpened)}">
        <div class="hamburger" :class="{blackHamb: $route.name == 'blog' || $route.name == 'post'}"></div>
      </div>
      <a href="#app" v-smooth-scroll="{ duration: 1500 }" id="backtotop" @click="backToTopMethod()" class="animated" :class="{fadeInUp: backToTop, fadeOutDown: !backToTop}">
        <!-- Nik imagekit-->
        <!--<img src="./assets/backtotop.png" alt="Back To Top" class="animated" :class="{bounce: bounce10}">-->
        <img src="https://ik.imagekit.io/nnol5se8mol/tr:w-76,h-72/backtotop_1yV4AHgTq.png" alt="Back To Top"
             class="animated" :class="{bounce: bounce10}">
      </a>
      <div id="menu" :class="{showMenu: showMenu, hideMenu: !showMenu}" class="loader-menu-common Menu">
        <ul class="Menu-list" data-offset="10">
          <li class="Menu-list-item" data-offset="20" @click="switchRoute({name: 'home'})">
            Home
            <span class="Mask"><span>Home</span></span>
            <span class="Mask"><span>Home</span></span>
          </li>
          <li class="Menu-list-item" data-offset="16" @click="switchRoute({name: 'services'})">
            Services
            <span class="Mask"><span>Services</span></span>
            <span class="Mask"><span>Services</span></span>
          </li>
              <li class="Menu-list-item" data-offset="10" @click="switchRoute({name: 'about'})">
            About
            <span class="Mask"><span>Meet⠀Clement</span></span>
            <span class="Mask"><span>Meet⠀Clement</span></span>
          </li>
          <li style="display: none !important" class="Menu-list-item" data-offset="12" @click="switchRoute({name: 'portfolio'})">
            Work
            <span class="Mask"><span>Work</span></span>
            <span class="Mask"><span>Work</span></span>
          </li>
          <li class="Menu-list-item" data-offset="8" @click="srormodal()">
            Contact
            <span class="Mask"><span>Contact</span></span>
            <span class="Mask"><span>Contact</span></span>
          </li>
          <!--<li class="Menu-list-item" data-offset="8" @click="switchRoute({name: 'blog'})">
            Blog
            <span class="Mask"><span>Blog</span></span>
            <span class="Mask"><span>Blog</span></span>
          </li>-->
        </ul>
      </div>
      <div id="loader" v-show="showLoaderAtAll" :class="{showMenu: showLoader, hideMenu: !showLoader, hideLoader2: (showLoader2 == 'no'), showLoader2: (showLoader2 == 'yes')}" class="loader-menu-common">
        <div>
          <lottie id="loaderAnim" :options="loaderAnimOptions" :height="350" :width="350" @animCreated="handleLoaderAnimation"/>
          <h1>{{msg}}</h1>
        </div>
      </div>
      <img src="./assets/cursor.png" id="mousePointer">
      <router-view @changeRoute="switchRoute($event)"/>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import Lottie from 'vue-lottie';
import * as loaderAnimationData from './assets/loaderAnimationData.json';
import * as logoAnimationData from './assets/logoAnimationData.json';

export default {
  //VueMeta
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Clement Labs',
    // all titles will be injected into this template
    titleTemplate: '%s | By Clement Labs'
  },
  components: {
    'lottie': Lottie
  },

  watch: {
    section() {
      if(document.querySelector('.container'))
        document.querySelector('.container').style.transform = `translateY(-${this.section * 100}vh)`
    },

    $route() {
      this.$store.state.modalOpened = false
    },

    modalOpened() {
      this.nameErr = false
      this.phoneErr = false
      this.emailErr = false
      this.messageErr = false
      this.name = ''
      this.phone = ''
      this.email = ''
      this.message = ''

    }
  },

  computed: {
    backToTop() {
      return !this.sectionExists ? (this.$store.state.sectionSpecial !== 0 ? true : false) : (this.section !== 0 ? true : false)
    },
    badgeShow() {
      return this.$store.state.badgeShow
    },
    footerData() {
      return this.$store.state.footerData
    },
    phoneWithNoSpaces() {
      return this.footerData.phone.replace(new RegExp(' ', 'gi'), '')
    },
    modalOpened() {
      return this.$store.state.modalOpened
    },
    section() {
      return this.$store.state.section
    },
    sectionSpecial() {
      return this.$store.state.sectionSpecial
    },
    formValid() {
      return (this.name !== '' && this.phone !== '' && this.email  !== '' && /\S+@\S+\.\S+/.test(this.email) && this.message !== '') ? true : false
    }
  },

  beforeCreate() {
    // window.scrollTo(0, 350)
  },

  created() {
    // document.querySelector("#app").style.display = 'none';
  },

  mounted() {

    //console.warn('update 3'); Nik

    if((window.navigator.userAgent.indexOf("Chrome-Lighthouse") !== - 1) && this.isMobile) {
      this.lazyLoad = false;
    }

    setInterval(() => {
      this.bounce10 = true
      setTimeout(() => {
        this.bounce10 = false
      }, 2000)
    }, 10000)

    var menu = document.querySelector('.Menu-list')
    var items = document.querySelectorAll('.Menu-list-item')
    var w = window.innerWidth
    var h = window.innerHeight

    let mousePointer = document.querySelector('#mousePointer')

    if(!this.isMobile) {
      window.onmousemove = (e) => {
        mousePointer.style.top = event.clientY + 'px'
        mousePointer.style.left = event.clientX + 'px'

        var offsetX = 0.5 - e.pageX / w //cursor position X
        var offsetY = 0.5 - e.pageY / h //cursor position Y
        var dy = e.pageY - h / 2 //@h/2 = center of poster
        var dx = e.pageX - w / 2 //@w/2 = center of poster
        var theta = Math.atan2(dy, dx) //angle between cursor and center of poster in RAD
        var angle = theta * 180 / Math.PI - 90 //convert rad in degrees
        var offsetPoster = menu.getAttribute('data-offset')
        var transformPoster = 'translate3d(0, ' + -offsetX * offsetPoster + 'px, 0) rotateX(' + (-offsetY * offsetPoster) + 'deg) rotateY(' + (offsetX * (offsetPoster * 4)) + 'deg)'

        //get angle between 0-360
        if (angle < 0) {
          angle = angle + 360;
        }

        menu.style.transform = transformPoster

        items.forEach(item => {
          var offsetLayer = item.getAttribute('data-offset') || 0
          var transformLayer = 'translate3d(' + offsetX * offsetLayer + 'px, ' + offsetY * offsetLayer + 'px, 20px)'
          item.style.transform = transformLayer
        })
      }

      window.onclick = () => {
        mousePointer.style.animation = 'clickAnimation 0.5s ease-in-out forwards'

          setTimeout(() => {
            mousePointer.style.animation = 'none'
          }, 500)
        }
    } else {
      mousePointer.style.display = 'none'
    }

    let self = this

    window.addEventListener("orientationchange", function() {
      setTimeout(() => {
        self.resetSections()
        window.scrollBy(0, -3000)
      }, 500)
    }, false);

    window.onscroll = () => {
      if(document.querySelector('.parallax')) {
        document.querySelectorAll('.parallax')[this.sectionSpecial].style.transform = 'translateY(' + (window.pageYOffset - document.querySelector(`#section-home-${this.sectionSpecial + 1}`).offsetTop) / 3 + 'px)'
      }

      if(window.pageYOffset > 50) {
        this.$store.state.offsetTop = true
      } else {
        this.$store.state.offsetTop = false
      }

      this.$store.state.sectionSpecial = Math.round(window.pageYOffset / window.innerHeight)

      if(document.querySelector('.section') !== null) {
        var st = window.pageYOffset
        document.body.style.overflowY = "hidden"
          if (!this.$store.state.scrollInProgress) {
            this.$store.state.scrollInProgress = true
            if (st >= this.$store.state.lastScrolltop){
              this.$store.state.section = this.section < document.querySelectorAll('.section').length - 1 ? this.section + 1 : this.section
            } else {
              this.$store.state.section = this.section > 0 ? this.section - 1 : this.section
            }

            setTimeout(() => {
              this.$store.state.scrollInProgress = false
              document.body.style.overflowY = "scroll"
            }, 1500)
          }
        this.$store.state.lastScrolltop = st <= 0 ? 0 : st
      }
    }

    this.animLoader.addEventListener("enterFrame", function (animation) {
     if (animation.currentTime > (self.animLoader.totalFrames - 1)) {
        this.animLoader.pause();
     }
    });

    this.animLoader.play()
    setTimeout(() => {
      this.$store.state.section = 0
    }, 2000)
    setTimeout(() => {
      this.showLoader = false
    }, 1500) // nik 3200 original, speed up animation
    setTimeout(() => {
      this.showLoaderAtAll = false
      this.showLoader = true
      this.showLoader2 = 'no'
    }, 3700)
    setTimeout(() => {
      this.showLoaderAtAll = true
    }, 4200)
  },

  methods: {
    checkPhone(e) {
      if(!(e.code.includes('Digit') || e.code.includes('Slash') || e.code.includes('Space'))) {
        e.preventDefault()
      }
    },

    backToTopMethod() {
      if(document.querySelector('.section')) this.$store.state.section = 0
    },

    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },

    srormodal() {
      if (window.innerWidth >= 1100) {
        this.switchRoute({name: 'contact'})
      } else {
        this.$store.state.modalOpened = true
        setTimeout(() => {
          this.showMenu = false
        }, 500)
      }
    },

    resetSections() {
      this.$store.state.section = 0
    },

    sendModalAttempt() {
      if(this.formValid) {
        let form2 = {
          name: this.name,
          phone: this.phone,
          email: this.email,
          message: this.message
        }

        const axiosConfig2 = {
          header: { "Content-Type": "application/x-www-form-urlencoded" }
        };
        axios.post(
          "/",
          this.encode({
            "form-name": "contact",
            ...form2
          }),
          axiosConfig2
        );

        this.$store.state.modalOpened = false
        setTimeout(() => {
          this.$store.state.badgeShow = true
        }, 500)
        setTimeout(() => {
          this.$store.state.badgeShow = false
        }, 2500)
        this.email = ''
        this.message = ''
        this.phone = ''
        this.name = ''
      } else {
        this.nameErr = this.name == '' ? true : false
        this.phoneErr = this.phone == '' ? true : false
        this.emailErr = (this.email == '' || !/\S+@\S+\.\S+/.test(this.email)) ? true : false
        this.messageErr = this.message == '' ? true : false
      }
    },

    openModal() {
      this.$store.state.modalOpened = true
    },

    switchRoute(route) {
      let msg = ""
      switch (route.name) {
        case "home":
          msg = "Homeward Bound";
          break;
        case "services":
          msg = "Tally-ho";
          break;
        case "about":
          msg = "Hold Tight";
          break;
        case "portfolio":
          msg = "Admire";
          break;
        case "blog":
          msg = "Good Reading Old Chap.";
          break;
        case "post":
          msg = "This could be funny... Or not... Tigerrrr!";
          break;
        case "contact":
          msg = "We should talk Old Boy";
          break;
        case "ServiceDataMigration":
          msg = "I like to Move IT move IT.. Splendid.";
          break;
        case "ServiceAppDevelopment":
          msg = "Need a custom app or new integration with your back office... Clement to the rescue! ";
          break;
        case "ServiceMarketing":
          msg = "Good stores need to be found.";
          break;
        case "ServiceShopifyRetailOnboarding":
          msg = "Where does a dog go when he loses his tail? A *retail* store. Indeed.";
          break;
        case "ServiceShopifyOnlineServices":
          msg = "Stand out from the crowd with a fancy online store front. Hurray!";
          break;
      }
      this.msg = msg

      this.showLoader2 = 'yes'
      setTimeout(() => {
        this.$router.push(route)
        this.$store.state.section = 0
        this.showMenu = false
      }, 500)
      setTimeout(() => {
        this.showLoader2 = 'no'
        this.sectionExists = document.querySelector('.section') == null ? false : true
      }, 2000)
    },

    handleLoaderAnimation(anim) {
      this.animLoader = anim;
      //nik Speed up animation
      this.animLoader.setSpeed(2);
    },

    handleLogoAnimation(anim) {
      this.animLogo = anim;
    },
  },

  data() {
    return {
      bounce10: false,
      loaderAnimOptions: {animationData: loaderAnimationData.default, loop: false, autoplay: false},
      logoAnimOptions: {animationData: logoAnimationData.default, loop: true, autoplay: false},
      showMenu: false,
      showLoader: true,
      showLoader2: '',
      showLoaderAtAll: true,
      msg: '',
      sectionExists: document.querySelector('.section') == null ? false : true,
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      name: '',
      phone: '',
      email: '',
      message: '',
      nameErr: false,
      phoneErr: false,
      emailErr: false,
      messageErr: false,
      lazyLoad: true
    }
  },
}
</script>


<style lang="sass">
/* eslint-disable */

.parallax
  overflow: hidden

@import '~animate.css'
@import 'https://use.typekit.net/ecv6mzb.css'

$footerBg: rgba(38, 70, 83, 1)
$menuBg: #3092d9
$loaderBg: #d45350

$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

.scaleYAnim
  opacity: 1 !important
  transform: translateY(0) !important

.downBtn
  position: fixed
  display: block
  z-index: 100
  bottom: 60px
  left: 0
  right: 0
  width: 50px
  height: 50px
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2)
    display: none
  @media (max-width: 1370px)
    position: absolute !important
    bottom: 0 !important
  img
    width: 100%
    height: 100%
  margin: auto
  font-size: 40px
  animation: float-y 2.5s ease-in-out infinite

.serviceParticularS1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: fixed
  z-index: 100
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  .downBtn
    position: absolute
    z-index: 500 !important
    bottom: 120px !important
    @media (orientation: landscape) and (max-width: 1320px)
      bottom: 15px !important
    left: 0
    right: 0
    width: 50px
    height: 50px
    margin: auto
    font-size: 40px
    animation: float-y 2.5s ease-in-out infinite
  h1
    @media (max-width: 1100px) and (min-width: 600px)
      font-size: 40px
    @media (max-width: 600px)
      font-size: 25px
    @media (orientation: landscape) and (max-width: 1320px)
      font-size: 20px
  img
    width: 20vw
    height: 20vw
    @media (max-width: 1100px) and (min-width: 600px)
      width: 30vw
      height: 30vw
    @media (max-width: 600px)
      width: 45vw
      height: 45vw
    @media (orientation: landscape) and (max-width: 1320px)
      width: 20vw
      height: 20vw

.serviceParticular
  background-color: $color5
  .serviceParticularS2
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    h1
      @media (max-width: 600px)
        font-size: 22px
        padding: 0 10px
      @media (min-width: 600px) and (max-width: 1100px)
        font-size: 30px
      @media (orientation: landscape) and (max-width: 1320px)
        font-size: 20px
    div
      display: flex
      width: 80vw
      @media (orientation: landscape) and (max-width: 1320px)
        width: 90vw !important
      @media (max-width: 600px)
        flex-direction: column
        width: 95vw
        span
          font-size: 16px !important
          margin: 15px !important
      @media (min-width: 600px) and (max-width: 1100px)
        flex-direction: column
        width: 80vw
        span
          font-size: 20px !important
      span
        text-align: left
        font-size: 20px
        margin: 30px 25px
        @media (orientation: landscape) and (max-width: 1320px)
          font-size: 14px !important
          margin: 10px 0 !important

.makeColumns
  flex-direction: column

.mgFeatures
  padding-top: 15vh
  box-sizing: border-box
  display: flex
  justify-content: flex-start
  align-items: center
  flex-direction: column
  h1
    @media (orientation: landscape) and (max-width: 1320px)
      font-size: 22px
  .mgFeaturesContent
    *
      text-align: left
    margin: 15px 10px
    @media (orientation: landscape) and (max-width: 1320px)
      margin: 0 !important
      div
        margin: 15px 0 0 0 !important
        width: 40vw !important
        margin-left: 10vw
        small
          display: none
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    div
      width: 40vw
      margin: 15px
      @media (max-width: 600px)
        width: 80vw
        margin: 5px 10px
      @media (min-width: 600px) and (max-width: 1100px)
        width: 70vw
      span
        font-size: 25px
        @media (max-width: 600px)
          font-size: 18px
          margin: 0 !important
        @media (min-width: 600px) and (max-width: 1100px)
          font-size: 20px
        @media (orientation: landscape) and (max-width: 1320px)
          font-size: 17px !important
      small
        font-size: 15px
        @media (max-width: 600px)
          display: none

.lastServicePartSection
  position: relative
  .tellusaboutyourprojectbtn
    position: relative
    top: 100px
    @media (min-width: 600px) and (max-width: 1100px)
      top: 300px !important
    @media (orientation: landscape) and (max-width: 1320px)
      top: 30px !important
    p
      @media (max-width: 1100px)
        width: 80vw
        margin: auto
        @media (orientation: landscape) and (max-width: 1320px)
          font-size: 15px !important
      font-size: 20px
    .btn
      width: 350px
      height: 150px
      @media (max-width: 800px)
        width: 70vw
        height: 80px
        max-width: 300px
        span
          font-size: 30px !important
          bottom: 38px
      @media (orientation: landscape) and (max-width: 1320px)
        width: 50vw
        height: 70px
        margin: 0 auto 10px auto !important
        span
          font-size: 27px !important
          bottom: 36px !important
      span
        font-size: 38px
        top: 33%
      &:hover
        span
          color: $color5

.footerServiceParticular
  position: absolute !important
  bottom: 0

#monocle
  position: fixed
  z-index: 500
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  width: auto
  height: 130vh
  @media (max-width: 1400px)
    transform: scale(4)
    width: 100vw !important
    height: auto !important
  @media (min-width: 1650px) and (max-height: 780px)
    height: 150vh
  @media (min-width: 1400px) and (max-height: 690px) and (max-width: 1650px)
    height: 150vh

#mailandphonemodal
  display: flex
  justify-content: center
  align-items: center
  margin: 0 auto
  position: relative
  @media (min-width: 1350px) and (max-height: 670px) and (min-height: 300px)
    right: -35vh !important
    div
      span
        display: none
    div:nth-child(1)
      top: 0 !important
      right: 0 !important
  @media (min-width: 1600px) and (min-height: 850px) and (max-height: 1000px)
    right: -28vh !important
    top: 15vh !important
    div
      a
        position: relative
        bottom: 5px
        right: 5px
        img
          width: 50px !important
          height: 50px !important
      span
        font-size: 18px !important
  @media (min-width: 1600px) and (min-height: 1000px)
    right: -28vh !important
    top: 15vh !important
    div
      a
        position: relative
        bottom: 5px
        right: 5px
        img
          width: 50px !important
          height: 50px !important
      span
        font-size: 21px !important
  @media (min-width: 1400px) and (max-height: 300px)
    display: none
  @media (max-width: 1400px)
    div
      span
        display: none
    div:nth-child(1)
      top: 0 !important
      right: 0 !important
  @media (min-width: 1400px) and (min-height: 670px)
    position: absolute
    right: -29vh
    flex-direction: column-reverse
    div:nth-child(1)
      position: relative
      top: 30vh
      right: 15px
  @media (min-width: 1650px) and (max-height: 780px)
    right: -39vh
  @media (min-width: 1400px) and (max-height: 690px) and (max-width: 1650px)
    right: -39vh
  @media (max-width: 400px)
    bottom: 50px
  @media (min-height: 830px) and (min-width: 1800px)

  div
    display: flex
    align-items: center
    justify-content: center
    span
      font-size: 16px
      @media (max-height: 750px)
        font-size: 14px
      font-weight: bold
    a
      display: inline-block
      width: 60px
      height: 60px
      img
        transition: all 0.4s ease-in-out
        &:hover
          transform: scale(1.2)
      img
        width: 40px
        height: 40px
        margin: 9px 5px

.mailandphone
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  @media (max-width: 600px)
    flex-direction: column
    div
      width: 230px
  .overflow-contact
    transition: transform 1.2s cubic-bezier(.165,.84,.44,1) .2s
    transform: translateY(100%)
  div
    margin: 0 20px
    a
      display: flex
      justify-content: center
      align-items: center
      @media (max-width: 600px)
        justify-content: flex-start
      &:hover
        img
          transform: scale(1.2)
      img
        width: 40px
        height: 40px
        margin: 5px 10px
        transition: all 0.4s ease-in-out

.redText
  border: 1.5px solid #d45350 !important

.modalPop-enter-active
  transition: all 1s ease-in-out 0.3s
  h2, form
    transition: all 1s ease-in-out 0.3s

.modalPop-leave-active
  transition: all 1s ease-in-out
  h2, form
    transition: all 1s ease-in-out

.modalPop-enter, .modalPop-leave-to
  opacity: 0
  h2, form
    opacity: 0

.opacitySwitch-enter-active, .opacitySwitch-leave-active
  transition: all 0.5s ease-in-out

.opacitySwitch-enter, .opacitySwitch-leave-to
  opacity: 0

.serviceFadeUp-enter-active, .serviceFadeUp-leave-active
  transition: all 1s ease-in-out
  .serviceIcon, h1
    transition: all 1s ease-in-out

.serviceFadeUp-enter, .serviceFadeUp-leave-to
  opacity: 0
  .serviceIcon, h1
    transform: translate(0, -150px)

.opacityTrans-enter, .opacityTrans-leave-to
  opacity: 0

.opacityTrans-enter-active, .opacityTrans-leave-active
  transition: all 1s ease-in-out
  opacity: 1

.monocleOpen-enter, .monocleOpen-leave-to
  transform: scale(0) !important

.monocleOpen-enter-active
  transition: all 0.8s ease-in-out

.monocleOpen-leave-active
  transition: all 0.8s ease-in-out 0.5s

.fade2-enter,
.fade2-leave-to
  opacity: 0
  transform: translateY(0) !important

.fade2-leave-active,
.fade2-enter-active
  transition: all 0.8s ease-in-out


.badgeShow
  opacity: 1 !important
  transform: translate(0) !important

.badge
  width: 220px
  height: 30px
  position: fixed
  top: 0
  left: 0
  right: 0
  margin: 30px auto
  z-index: 120
  display: flex
  align-items: center
  justify-content: space-around
  background-color: $menuBg
  padding: 15px 10px 15px 5px
  border-radius: 40px
  opacity: 0
  transition: all 0.6s ease
  transform: translate(0, -50px)
  img
    width: 30px
    height: 30px

#contactPopup
  position: fixed
  top: 10vh
  box-sizing: border-box
  @media (orientation: landscape) and (max-width: 1320px)
    height: auto !important
    width: 100vw !important
    form
      width: 80% !important
      height: auto !important
      margin-left: auto
      margin-right: auto
    overflow-y: scroll !important
    overflow-x: visible !important
  form
    input, textarea
      -webkit-appearance: none !important
  @media (max-width: 1350px)
    width: 100vw
    height: 100vh
    left: 0
    bottom: 0
    top: 0
    border: 0 $footerBg solid
    transform-origin: center
    form
      width: 70%
      height: 100%
      padding-top: 10px
  @media (max-width: 350px)
    form
      position: relative
      bottom: 50px
  @media (min-width: 1350px)
    left: 0
    right: 0
    bottom: 0
    width: 90vh
    height: 90vh
    border-radius: 20px
  @media (min-width: 1350px)
    .contactForm
      input, textarea
        font-size: 15px
        padding: 3px !important
  @media (min-height: 900px) and (min-width: 1550px)
    form
      input, textarea
        font-size: 20px !important
        padding: 10px !important
        margin-bottom: 5vh
    h2
      font-size: 30px
  margin: auto
  z-index: 550
  h2
    padding: 6% 0 0 0
    @media (max-width: 600px)
      visibility: hidden !important
      padding-top: 2% !important
  .btn
    width: 120px
    @media (max-width: 500px)
      position: relative
      bottom: 25px
    &:hover
      span
        color: $footerBg

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus
  -webkit-text-fill-color: #fff
  -webkit-box-shadow: 0 0 0px 1000px transparent inset
  transition: background-color 5000s ease-in-out 0s


.contactForm
  width: 70%
  @media (max-width: 770px)
    input, textarea
      margin-bottom: 10px !important
      padding: 5px !important
      font-size: 12px !important
  @media (min-width: 770px) and (max-width: 1100px)
    width: 60%
  margin: auto
  display: flex
  flex-direction: column
  align-items: flex-start
  small
    margin: 0 auto
  input, textarea
    -webkit-appearance: none !important
    margin: 5px 0 30px 0
    padding: 10px
    font-size: 20px
    width: 100%
    background-color: transparent
    border-radius: 15px
    transition: all 0.6s ease
    border: 1.5px solid #fff
    color: #fff
    &:focus
      border: 1.5px solid #2c5d94 !important

// MENU STYLING

// === Variables =====
$perspective: 1060rem
$font-size: 4.25rem
$split-position: 49%
$split-thickness: 4px
$split-color: #FF2C75

// === Menu <ul> =====
.Menu-list
  font-size: $font-size
  @media (max-width: 600px)
    font-size: 40px
  @media (orientation: landscape) and (max-width: 1320px)
    font-size: 40px !important
  line-height: 1.2
  padding-left: 0 !important
  text-transform: uppercase
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  perspective: $perspective

// === Menu item =====
.Menu-list-item
  position: relative
  color: transparent
  perspective: $perspective
  &:nth-child(3)
    position: relative
    right: 85px
    &::before
      width: 550px
      @media (max-width: 600px)
        width: 330px
      @media (orientation: landscape) and (max-width: 1320px)
        width: 330px
  // === Split Line =====
  &::before
    content: ''
    display: block
    position: absolute
    top: $split-position
    left: -10%
    right: -10%
    height: $split-thickness
    border-radius: $split-thickness
    margin-top: -($split-thickness / 2)
    background: $split-color
    transform: scale(0)
    transition: transform .8s cubic-bezier(.16,1.08,.38,.98)
    z-index: 1

// === Top/bottom mask =====
.Mask
  display: block
  position: absolute
  overflow: hidden
  color: $split-color
  top: 0
  height: $split-position
  transition: all .8s cubic-bezier(.16,1.08,.38,.98)

  span
    display: block

// === Bottom mask specific =====
.Mask + .Mask
  top: $split-position - 0.1
  height: 100 - $split-position + 0.1

  span
    transform: translateY(-$split-position)

// === Where the fun stuff happens =====
.Menu-list-item:hover,
.Menu-list-item:active
  .Mask
    color: #FFF
    transform: skewX(12deg) translateX(5px)
  .Mask + .Mask
    transform: skewX(12deg) translateX(-5px)
  &::before
    transform: scale(1)
//

*
  text-decoration: none
  color: #fff
  cursor: none
  user-drag: none
  -webkit-user-drag: none
  outline: none

  *:not(input)
    -o-user-select: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  input, textarea
    -webkit-user-select: text !important
    -khtml-user-select: text !important
    -moz-user-select: text !important
    -ms-user-select: text !important
    user-select: text !important

  @keyframes float-y
    0%
      transform: translateY(0)
    50%
      transform: translateY(-15px)

  @keyframes float-y-180
    0%
      transform: translateY(0) rotateZ(180deg)
    50%
      transform: translateY(-15px) rotateZ(180deg)
    100%
       transform: rotateZ(180deg)

  #footer
    width: 100vw
    height: 204px
    padding: 40px
    box-sizing: border-box
    position: relative
    z-index: 102
    background-color: $footerBg
    @media (max-width: 1100px)
      padding: 0
      height: 190px
    .ftop
      margin-bottom: 20px
      display: flex
      justify-content: center
      align-items: center
      @media (max-width: 1100px)
        flex-direction: column
      div
        margin: 0 40px
      .ftop1
        h2
          margin-bottom: 0
        .icons
          a
            &:first-child
              img
                margin-left: 0
            img
              width: 30px
              height: 30px
              margin: 10px 5px
              transition: all 0.4s ease-in-out
            &:hover
              img
                transform: scale(1.2)
      .ftop2
        margin-top: 10px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        @media (max-width: 1100px)
          margin: 0
          flex-direction: row
          align-items: center
        div
          @media (max-width: 1100px)
            margin: 0
          a
            display: flex
            justify-content: center
            align-items: center
            &:hover
              img
                transform: scale(1.2)
            img
              width: 30px
              height: 30px
              margin: 5px 10px
              transition: all 0.4s ease-in-out
              @media (max-width: 1100px)
                margin: 0 5px
            span
              @media (max-width: 1100px)
                display: none
      .ftop3
        @media (max-width: 1100px)
          display: none
        div
          position: relative
          right: 40px
        &:hover
          span
            color: $footerBg
    .fbottom
      hr
        width: 50vw
      small
        font-size: 14px
        @media (orientation: landscape) and (max-width: 1320px)
          position: relative
          bottom: 3px

.navbarServices
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100px
  z-index: 50
  background-color: $color5
  @media (max-width: 800px)
    display: none
  @media (orientation: landscape) and (max-width: 800px)
      display: none

.btn
  width: 160px
  height: 50px
  border-radius: 5px
  margin: 40px auto
  overflow: hidden
  box-shadow: inset 0px 0px 0px 2px #fff
  position: relative
  transition: all 0.8s ease
  div
    box-sizing: border-box
    width: 100%
    height: 100%
    transform-origin: left
    transform: scaleX(0)
    transition: 0.8s ease
    background-color: #fff
  span
    position: absolute
    top: 32%
    height: 30%
    margin: auto
    left: 0
    right: 0
    transition: all 0.8s ease
  &:hover
    div
      transform: scaleX(1)

.underlined
  transform: scaleX(1) !important

@keyframes clickAnimation
  0%
    transform: rotate(0)
  50%
    transform: rotateZ(-15deg) rotateX(30deg)
  100%
    transform: rotate(0)

@keyframes float-x
  0%
    transform: translate(-7px, 0) rotateZ(270deg)
  50%
    transform: translate(7px, 0) rotateZ(270deg)
  100%
    transform: translate(-7px, 0) rotateZ(270deg)

body::-webkit-scrollbar
  width: 0 !important

html
  background-color: $loaderBg

body
  margin: 0
  overflow: -moz-scrollbars-none
  -ms-overflow-style: none
  //nik fix overflow
  //overflow: none

  //font-family: neue-kabel, sans-serif
  //font-family: miller-display, serif
  font-family: goudy-old-style, serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #fff
  background-color: $loaderBg
  cursor: none
  overflow-x: hidden !important
  width: 100vw
//  height: auto !important
  /*height: 1000vh*/

.body-data
  overflow: visible !important
  height: auto !important

@keyframes slideInTop
  from
    opacity: 0
    transform: translateY(-100px)
  to
    opacity: 1
    transform: translateY(0)

.container
  position: fixed
  left: 0
  top: 0
  width: 100vw
  transition: 1s ease-in-out
  .section
    height: 100vh
    width: 100vw

#mousePointer
  position: fixed
  z-index: 2000
  pointer-events: none

.logo-wrapper
  @media (max-width: 800px)
    display: none

#logo
  position: fixed
  top: 15px
  left: 20px
  width: 180px
  height: 108px
  // cursor: pointer
  z-index: 1001

.logoBlack
  position: fixed
  top: 40px
  left: 80px
  height: 80px
  z-index: 1002
  @media (max-width: 1100px)
    display: none

.loader-menu-common
  width: 100vw
  height: 100vh
  position: fixed
  transition: 0.5s ease-out
  top: 0
  left: 0
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  overflow: hidden

#loader
  z-index: 2001
  background-color: $loaderBg
  div
    #loaderAnim
      position: relative
      left: 5px
    h1
      font-style: normal
      margin: 0 !important
      position: relative
      bottom: 50px

#backtotop
  width: 76px
  height: 72px
  img
    width: 100%
    height: 100%
  position: fixed
  bottom: 30px
  right: 30px
  @media (max-width: 600px)
    bottom: 10px
    right: 10px
  z-index: 300

#menu
  background: $menuBg
  z-index: 1000


.showMenu
  transform: translateY(0vh)

.hideMenu
  transform: translateY(-100vh)

.showloader2
  transform: translate(0, 0) !important

.hideLoader2
  transform: translate(-100vw, 0) !important

.icon
  position: fixed
  right: 20px
  top: 60px
  transform: translate(-50%, -50%)
  width: 60px
  height: 60px
  z-index: 1200
  @media (max-width: 800px)
    right: -10px
    top: 50px

.hamburger
  width: 38px
  height: 4px
  background: #fff
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  box-shadow: 0 2px 5px rgba(0,0,0,0.2)
  transition: all 0.5s ease-in-out

.blackHamb
  background-color: black
  &:before, &:after
    background-color: black !important

.hamburger:before,
.hamburger:after
  content: ''
  position: absolute
  width: 38px
  height: 4px
  background: #fff
  transform: translate(-50%)
  box-shadow: 0 2px 5px rgba(0,0,0,0.2)
  transition: 0.5s

.hamburger:before
  top: -12px

.hamburger:after
  top: 12px

.icon.active .hamburger
  background: transparent
  box-shadow: none

.icon.active .hamburger:before
  transform: rotate(45deg)
  top: 0
  left: 0

.icon.active .hamburger:after
  transform: rotate(135deg)
  top: 0
  left: 0

</style>
